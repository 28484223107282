<template>
    <div>
      <header class="page-header bg-primary">
        <div class="logo-container">
          <img :src="Logo" alt="Start Waves" class="logo" />
        </div>
        <div class="flex">
          <i class="fa fa-users" aria-hidden="true"></i>
          <span class="page-title">
            About Us
          </span>
        </div>
        <button class="menu-toggle-btn" @click="toggleMenu">
          <i class="fa fa-bars fa-2x"></i>
        </button>
        <div class="menu" :class="{ 'menu-open': isMenuOpen }">
          <router-link to="/" class="menu-item" @click="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
            Home
          </router-link>
          <router-link to="/" class="menu-item disabled" @click.prevent="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
            About Us
          </router-link>
          <router-link to="/contact" class="menu-item" @click="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
            Contact
          </router-link>
          <!-- Add more menu items as needed -->
        </div>
      </header>
  
      <div class="page-container about-us-container">
        <div class="about-us-content">
          <section class="intro-section">
            <h1>Welcome to Start Waves FinTech</h1>
            <p>
              At Start Waves, we believe in making banking accessible to all. Our mission is to provide innovative and inclusive financial solutions that empower individuals and businesses to achieve their financial goals.
            </p>
          </section>
  
          <section class="mission-section">
            <h2>Our Mission</h2>
            <p>
              Our mission is to revolutionize the financial industry by leveraging cutting-edge technology. We strive to create a positive impact on the lives of our users by offering a seamless and user-friendly banking experience.
            </p>
          </section>
  
          <section class="team-section">
            <h2>Meet Our Team</h2>
            <p>
              Our diverse team of professionals is dedicated to driving positive change in the financial landscape. Meet the minds behind Start Waves - FinTech Solutions.
            </p>
            <!-- Add team member profiles or images here -->
          </section>
  
          <section class="values-section">
            <h2>Our Core Values</h2>
            <ul>
              <li>Customer-Centricity</li>
              <li>Innovation</li>
              <li>Integrity</li>
              <li>Collaboration</li>
            </ul>
          </section>

          <section class="values-section">
            <h2>Banking for all</h2>
          </section>

          <section class="values-section">
            <h2></h2>
          </section>
        </div>
      </div>
  
      
    </div>
  </template>
  
  <script>
  import Logo from '@/assets/sw_logo_svg_3.svg';
  
  export default {
    name: 'AboutUs',
    data() {
      return {
        Logo: Logo,
        isMenuOpen: false,
      };
    },
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
      closeMenu() {
        this.isMenuOpen = false;
      },
    },
  };
  </script>
  
  <style>
  /* Add any additional styles specific to the About Us page */
  .about-us-container {
    padding: 20px;
  }
  
  .about-us-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .intro-section {
    text-align: center;
  }
  
  .mission-section,
  .team-section,
  .values-section {
    margin-top: 40px;
  }
  
  .values-section ul {
    list-style-type: none;
    padding: 0;
  }

  @media screen and (max-width: 640px) {
  .page-title {
    text-align: center; /* Alinear el texto al centro en dispositivos con ancho máximo de 768px */
    display: none; /* Asegurar que el texto tenga un comportamiento de bloque para ocupar todo el ancho disponible */
  }

  .menu-item.disabled {
    pointer-events: none; /* Evita que el enlace sea clickeable */
    color: #ccc; /* Cambia el color del texto para indicar visualmente que está desactivado */
    cursor: not-allowed; /* Cambia el cursor a "no permitido" para indicar que no se puede hacer clic */
  }

  .icon {
  display: none; /* Por defecto, oculta el icono */
  width: 24px;
  height: 24px;
  margin-right: 8px; /* Espacio entre el icono y el texto */
}

@media screen and (min-width: 640px) {
  .icon {
    display: none; /* Muestra el icono en modo responsive */
  }
}

}
  
  /* Customize styles as necessary for the About Us page */
  </style>
  